import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import SideBar from "../components/Navigation/SideBar/SideBar";
import NavBar from "../components/Navigation/NavBar/NavBar";
import Footer from "../components/Navigation/Footer/Footer";
import { useSelector } from "react-redux";
import useWindowDimensions from "../utils/useDimension";
import SideBarTablet from "../components/Navigation/SideBar/SideBarTablet";

const SidebarLayout = ({ children, ...props }) => {
  const mainStyle = {
    height: "968px",
    background: "#eee",
  };

  const toggle = useSelector((state) => state.toggleMenu);
  let dimension = useWindowDimensions();


  console.log("Dim", dimension);
  return (
    <div id="app" className={"wrapper"}>
      <div className="navbar-wrapper">
        <NavBar />
      </div>
      <div className="wrapper-paragraph">
      {dimension.width > 768 && (
        <>
        <h1 className="wrapper-title">Gestalte & bestelle jetzt Dein individuelles <span className="wrapper-title-nestead">neon Schild !</span></h1>
        <p className="wrapper-text">LED Neon als moderner Nachwuchs der Neon.</p>
        </>
      )}
        {dimension.width < 768 && (
           <h1 className="wrapper-title_mb">Gestalte & bestelle jetzt Dein individuelles <span className="wrapper-title-nestead">neon Schild !</span></h1>
        )}
       
      </div>
      {dimension.width > 1024 && (
          <div className={`main-wrapper-release`}>
          <div className="sidebar-wrapper-release">
              <SideBar/>
          </div>
          <div className="container-wrapper-release">
            <main>{children}</main>
          </div>
        </div>
      )}
      {(dimension.width <= 1024 && dimension.width >= 768 ) && (
          <div className={`main-wrapper-release_tb`}>
          <div className="container-wrapper-release_tb">
            <main>{children}</main>
          </div>
          <div className="sidebar-wrapper-release_tb">
              <SideBarTablet/>
          </div>
        </div>
      )}
      {dimension.width < 768  && (
          <div className={`main-wrapper-release_mb`}>
          <div className="container-wrapper-release_mb">
            <main>{children}</main>
          </div>
          <div className="sidebar-wrapper-release_mb">
              <SideBar/>
          </div>
        </div>
      )}
      
      <Footer />
    </div>
  );
};
const SidebarLayoutRoute = ({ component: Component, isAuth, ...rest }) => {
  if (isAuth) {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <SidebarLayout>
            <Component {...matchProps} />
          </SidebarLayout>
        )}
      />
    );
  } else return <Redirect to="/" />;
};

export default SidebarLayoutRoute;
