import React, { useEffect, useState } from "react";

const Expanded = ({ children }) => {
    
  return <div className="accordion-content">
      {children}
      </div>
};


export default Expanded;
