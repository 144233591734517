import firebase from "../../firebase/firebase"

export const fontsList = () => async dispatch => {


    const ref = firebase.firestore().collection("fonts")
  
    try {
        
        dispatch({
            type: "FONT_LIST_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "FONT_LIST_SUCCESS",
                payload: items
            })         
        })   
      
    } catch (error) {
        dispatch({
            type: "FONT_LIST_FAIL"
        })
    }
}