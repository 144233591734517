import { updateText, updateSize, theme, addToCart, addToCartOption, loadingPage, validateInput, validateInputEmpty } from "./utility"
import { toggleMenu } from "./menu"
import { getFonts, updateFontFamily } from "./fonts"
import { getColors, updateColor, updateColorName } from "./colors"
import { updateSvg } from "./output"
import { getBackground, updatePlexes, updatePlexesName } from "./background"
import { getPVC, updatePvc, updatePvcName } from './pvc'
import { getInstallationOption, updateInstallation, updateInstallationName } from "./installation"
import { getCables, updateCable, updateCableName } from "./cables"
import { getOption, updateMoreOption } from "./option"
// import { factors } from "./factors"
import { getSetupPrice, updateSetupName } from "./setupPrice"
import { updateWooProduct, createWooProduct } from "./WooProudctReducer"
import { getFiles } from "./files"


export default {
    updateText,
    toggleMenu,
    getFonts,
    updateFontFamily,
    getColors,
    updateColor,
    updateSize,
    getBackground,
    getPVC,
    getInstallationOption,
    getCables,
    theme,
    getOption,
    output: updateSvg,
    getSetupPrice,
    updatePlexes,
    updatePvc,
    updateMoreOption,
    updateCable,
    updateInstallation,
    updateWooProduct,
    addToCart,
    addToCartOption,
    updateColorName,
    updatePlexesName,
    updatePvcName,
    updateInstallationName,
    updateCableName,
    createWooProduct,
    loadingPage,
    validateInput,
    validateInputEmpty,
    getFiles,
    updateSetupName
    // factors

}