import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import { combineReducers } from "redux"
import rootReducer from "../reducers/index"

const reducer = combineReducers({
    ...rootReducer
})

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export { store }

