import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

const defaultState = {
    color: "",
}

const defaultStateName = {
    plexesName: "",
    bgOption: false
}

export const getBackground = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.BACKGROUND_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.BACKGROUND_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the background colors"
            }
        case ActionTypes.BACKGROUND_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updatePlexes = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_PLEXES:
            return {
                ...state,
                color: payload.color,
            }
        default:
            return state
    }
}

export const updatePlexesName = (state = defaultStateName, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_PLEXES_NAME:
            return {
                ...state,
                plexesName: payload.plexesName,
            }
        case ActionTypes.UPDATE_SETUP_OPTION:
            return {
                ...state,
                bgOption: payload.bgOption,
            }
        default:
            return state
    }
}