import firebase from "../../firebase/firebase"

export const pvcList = () => async dispatch => {

    const ref = firebase.firestore().collection("pvc")
    
   /*  const pvc = [];

        ref.onSnapshot((querySnapshot) => { 
            querySnapshot.forEach((doc) => {
                pvc.push(doc.data())
            });
        }) */

    try {
        dispatch({
            type: "PVC_LIST_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "PVC_LIST_SUCCESS",
                payload: items
            })         
        }) 

        /* dispatch({
            type: "PVC_LIST_SUCCESS",
            payload: pvc
        }) */
    } catch (error) {
        dispatch({
            type: "PVC_LIST_FAIL"
        })
    }
}