import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: "",
    setupName: ""
}

const defaultState = {
    setupName: "",
}

export const getSetupPrice = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.SETUP_PRICE_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.SETUP_PRICE_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the setup price"
            }
        case ActionTypes.SETUP_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updateSetupName = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_SETUP_NAME:
            return {
                ...state,
                setupName: payload.setupName,
            }

        default:
            return state
    }
}