import React from "react";

const Option = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill="none"
      className={props.className}
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0001 56.8334C16.7659 56.8334 5.16675 45.2342 5.16675 31C5.16675 16.7659 16.7659 5.16669 31.0001 5.16669C45.2343 5.16669 56.8334 15.6034 56.8334 28.4167C56.8334 36.9675 49.8843 43.9167 41.3334 43.9167H36.7609C36.0376 43.9167 35.4692 44.485 35.4692 45.2084C35.4692 45.5184 35.5984 45.8025 35.8051 46.0609C36.8642 47.275 37.4584 48.7992 37.4584 50.375C37.4584 52.0879 36.778 53.7306 35.5668 54.9418C34.3556 56.1529 32.7129 56.8334 31.0001 56.8334ZM31.0001 10.3334C19.6076 10.3334 10.3334 19.6075 10.3334 31C10.3334 42.3925 19.6076 51.6667 31.0001 51.6667C31.7234 51.6667 32.2917 51.0984 32.2917 50.375C32.2837 50.04 32.1553 49.719 31.9301 49.4709C30.8709 48.2825 30.3026 46.7584 30.3026 45.2084C30.3026 43.4955 30.983 41.8528 32.1942 40.6416C33.4054 39.4305 35.0481 38.75 36.7609 38.75H41.3334C47.0426 38.75 51.6668 34.1259 51.6668 28.4167C51.6668 18.445 42.3926 10.3334 31.0001 10.3334Z"
        stroke={props.color}
        fill={props.fill}
        //stroke-width="2"
        //stroke-linecap="square"
        onClick={props.onClick}
      />
      <path
        d="M16.7917 33.5833C18.9319 33.5833 20.6667 31.8484 20.6667 29.7083C20.6667 27.5682 18.9319 25.8333 16.7917 25.8333C14.6516 25.8333 12.9167 27.5682 12.9167 29.7083C12.9167 31.8484 14.6516 33.5833 16.7917 33.5833Z"
        stroke={props.color}
        fill={props.fill}
        //stroke-width="2"
        //stroke-linecap="square"
        onClick={props.onClick}
      />
      <path
        d="M24.5417 23.25C26.6819 23.25 28.4167 21.5151 28.4167 19.375C28.4167 17.2349 26.6819 15.5 24.5417 15.5C22.4016 15.5 20.6667 17.2349 20.6667 19.375C20.6667 21.5151 22.4016 23.25 24.5417 23.25Z"
        stroke={props.color}
        fill={props.fill}
        //stroke-width="2"
        //stroke-linecap="square"
        onClick={props.onClick}
      />
      <path
        d="M37.4583 23.25C39.5984 23.25 41.3333 21.5151 41.3333 19.375C41.3333 17.2349 39.5984 15.5 37.4583 15.5C35.3181 15.5 33.5833 17.2349 33.5833 19.375C33.5833 21.5151 35.3181 23.25 37.4583 23.25Z"
        stroke={props.color}
        fill={props.fill}
        //stroke-width="2"
        //stroke-linecap="square"
        onClick={props.onClick}
      />
      <path
        d="M45.2083 33.5833C47.3484 33.5833 49.0833 31.8484 49.0833 29.7083C49.0833 27.5682 47.3484 25.8333 45.2083 25.8333C43.0681 25.8333 41.3333 27.5682 41.3333 29.7083C41.3333 31.8484 43.0681 33.5833 45.2083 33.5833Z"
        stroke={props.color}
        fill={props.fill}
        //stroke-width="2"
        //stroke-linecap="square"
        onClick={props.onClick}
      />
    </svg>
  );
}

export default Option;
