import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../../redux/constant";
import Button from "../../FormElement/Button/Button";
import Model from "../../Model/CheckoutModel";
import { storage } from '../../../firebase/firebase'
//import { updateWOOProduct} from "../../../redux/actions/WooProudctAction";
import {
  fetchUpdateWooProduct,
  fetchGetWooProduct,
  validationInput
} from "../../../redux/actions/WooProudctAction";
import { useHistory } from 'react-router-dom';
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import Axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const CheckoutCard = (props) => {
  const modelRef = useRef(null);
  const handleClick = (e) => {
    modelRef.current.openModel();
  };

  const [checkout, setCheckout] = useState(null)
  const [idState, setIDState] = useState(null);
  const [productImage, setProductImage] = useState(null);



  const useStickyState = (defaultValue, key) => {
    const [value, setValue] = React.useState(() => {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null
        ? JSON.parse(stickyValue)
        : defaultValue;
    });
    React.useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
  }

  const dispatch = useDispatch();

  const output = useSelector((state) => state.output);

  const countA2C = useSelector((state) => state.addToCart);

  let { lm, cl, pvc, plx, mo, io, ic, setupPrices } = output.factors;
  const { charCount, pathLength } = output;


  
  const getsvg = useSelector((state) => state.updateSvg);
  let { svg, height, width } = output;

  const filename = "svg_path"



  const cartOptions = useSelector((state) => state.addToCartOption);

  const getFont = useSelector((state) => state.updateFontFamily);

  const getColor = useSelector((state) => state.updateColorName);

  const getPlexes = useSelector((state) => state.updatePlexesName);

  const getMoreOption = useSelector((state) => state.updateMoreOption);

  const getPvcColor = useSelector((state) => state.updatePvcName);

  const getProduct = useSelector((state) => state.updateWooProduct);
  const getSetupPrice = useSelector((state) => state.getSetupPrice);

  const getInstallationOption = useSelector(
    (state) => state.updateInstallationName
  );

  const getCableOption = useSelector((state) => state.updateCableName);
  const createProduct = useSelector((state) => state.createWooProduct);

  const setupPrice = useSelector((state) => state.updateSetupName);

  let { text } = cartOptions;

  let { fontName } = getFont;

  let { colorName } = getColor;

  let { plexesName, bgOption } = getPlexes;

  let { optionName } = getMoreOption;

  let { pvcName } = getPvcColor;

  let { cableName } = getCableOption;

  let { installationName } = getInstallationOption;

  let { setupName } = setupPrice;

  console.log("All factories", output.factors);
  //updateProduct(658, data);

  //basic_price=(path_length x lm) + (no_of_alphabet x setup_price)
  //price=basic_price  + basic_price(((cl+pvc+plx+mo+io)- (count_of_factors-1) )  +ic

  let basic_price = pathLength * lm + charCount * setupPrices;

  let totalPrice =
    (basic_price + (basic_price * (cl + pvc + plx + mo + io - 4) + ic)) * countA2C.count;



  let history = useHistory();
  /* useEffect(() => {
    dispatch(fetchGetWooProduct());
    dispatch(validationInput())
    
  }, []) */

  const [data, setData] = useState({})

 /*  useEffect(() => {
    let uid = Math.floor(Math.random() * 100);
    const d={
      name: `${text}`,
      slug: `${uid}`,
      type: "simple",
      regular_price: `${totalPrice}`,
      description: `<ul>
                      <li>Neon Art: ${setupName}</li>
                      <li>Font: ${fontName}</li>
                      <li>Width x Height: ${width}cm x ${height}cm</li>
                      <li>Light Color: ${colorName}</li>
                      <li>Background: ${!bgOption ? plexesName + "+" + optionName : pvcName}</li>
                      <li>Installation Options: ${installationName}</li>
                      <li>Connectoin Cable Lenght: ${cableName}</li>
                    <ul>
                    `,
      images: [
        {
          src:
            "https://cdn.futura-sciences.com/buildsv6/images/largeoriginal/c/f/a/cfa47a2952_50037812_netube-02.jpg",
        },
      ],
    }
    setData(d)
    setCheckout({ product: d });

    //download(filename, svg);
    getProduct.data.map((product) => {
      //setElementID(elementID.push(parseInt(product.id)))
      setElementID(elementID => [...elementID, parseInt(product.id)])

    });

  }, [text, setupName, bgOption, totalPrice, fontName, width, height, colorName, plexesName, optionName, pvcName, installationName, cableName, cartOptions, getProduct.data]);


 */

  const [elementID, setElementID] = useState([]);

  //const [count, setCount] = useStickyState(elementID[0] + 2, "count");


 /*  const { validate } = useSelector(
    state => ({ validate: state.validateInput.validate })
  ); */


  const wooProductUpdate = async () => {
    // const image = await download()

    // const formData = new FormData();
    // var fileOfBlob = new File([image], "svg.png");
    // formData.append("svg.png", fileOfBlob);
    // const request = new XMLHttpRequest();

    // request.open('POST', `https://neon-fabrik-backend-api.herokuapp.com/upload`);

    // request.send(formData);
    dispatch({
      type: ActionTypes.SHOW_LOADER,
      payload: {
        laoding: true,
      },
    })

    const WOOCOMERCE_URL = "https://neonfabrik.de"
    const image = await download();
    const formData = new FormData();
    var fileOfBlob = new File([image], `svg-${uuidv4()}.png`);
    formData.append('files', fileOfBlob)
    console.log(formData);
    let response=null
    
   

   /*  try {
      response=await Axios.post("https://neon-fabrik-backend-api.herokuapp.com/upload", formData)
      //console.log(`---: wooProductUpdate -> response`, response)
    } catch (error) {
      console.log(error);
    } */

     /* let bucketName = 'images'
     let file = fileOfBlob
     let storageRef = storage.ref(`${bucketName}/${file.name}`)
     let uploadTask = storageRef.put(file)
     uploadTask.on(storage.TaskEvent.STATE_CHANGE,
      () =>{
         let downloadURL = uploadTask.snapshot.downloadURL
      })


      let storageRefShow = storage.ref()
      let spaceRef = storageRef.child('images/' + fileOfBlob.name)
      storageRefShow.child('images/' + +fileOfBlob.name)
      .getDownloadURL()
      .then((url) => {
        console.log(url)
      }) */
      var metadata = {
        contentType: 'image/png',
      };

    const uploadImage = storage.ref(`/${fileOfBlob.name}`).put(fileOfBlob, metadata);
    uploadImage.on(
      'state_changed',
      snapshot => {},
      error => {
        console.log(error)
      },
      () => {
         storage.ref('').child(fileOfBlob.name).getDownloadURL().then(url => {
          window.location=`${WOOCOMERCE_URL}/cart/?product_id=2831&para_quantity=${countA2C.count}&para_name=${text.replace(/ /g,'')}&para_price=${totalPrice >> 0}&para_neonart=${setupName}&para_font=${fontName}&para_width=${width >> 0}cm&para_height=${height >> 0}cm&para_lightcolor=${colorName}&para_background=${!bgOption ? plexesName + "+" + optionName : pvcName}&para_installationoptions=${installationName}&para_cablelenght=${cableName}&para_image=${url}`
          //window.location=`${URL}/shop/cart/?quantity=${countA2C.count}&name=${text}&regular_price=${totalPrice}&neonart=${setupName}&font=${fontName}&width=${width}cm&height=${height}cm&lightcolor=${colorName}&Background=${!bgOption ? plexesName + "+" + optionName : pvcName}&installationoptions=${installationName}&cablelenght=${cableName}&src=${url}`
          //productImage = url
        })
    }
  )
};
    /* snapshot.ref.getDownloadURL().then(function(downloadURL) {
      console.log("File available at", downloadURL);
    }); */
    //const imageUrl=response?.data && response?.data[0]?.url;
    /*  let isValid = true;
 
     console.log("Here validate function", validate);
     if(!validate){
        isValid = false
     }
 
     dispatch({
       type: ActionTypes.SHOW_ERROR,
       payload: {
         error: isValid,
       },
     });
 
     if (!isValid) {
       return;
     }
      */

    //console.log("Validate in checkout ---->", isValid);
    /* const URL = `${process.env.REACT_APP_WOOCOMERCE_URL}`;

    let product_copy=checkout.product
     product_copy={...product_copy,images: [
      {
        src:imageUrl
      },
    ],} */
     //console.log(`---: wooProductUpdate -> product_copy`, product_copy)
    /* setCheckout({product:product_copy})

    dispatch(fetchUpdateWooProduct(product_copy)) */
   

    //setTimeout(() => {

      /* dispatch({
        type: ActionTypes.HIDE_LOADER,
        payload: {
          laoding: false,
        },
      }); */

      //window.location=`${URL}/shop/cart/?add-to-cart=${elementID[0] + 2}&quantity=${countA2C.count}`;
      //window.location=`${URL}/shop/cart/?add-to-cart=${elementID[0] + 2}&quantity=${countA2C.count}&name=${text}&regular_price=${totalPrice}&neonart=${setupName}&font=${fontName}&width=${width}cm&height=${height}cm&lightcolor=${colorName}&Background=${!bgOption ? plexesName + "+" + optionName : pvcName}&installationoptions=${installationName}&cablelenght=${cableName}&src=${imageUrl}`
      //window.location=`${URL}/shop/cart/?add-to-cart/quantity=${countA2C.count}&name=${text}&regular_price=${totalPrice}&neonart=${setupName}&font=${fontName}&width=${width}cm&height=${height}cm&lightcolor=${colorName}&Background=${!bgOption ? plexesName + "+" + optionName : pvcName}&installationoptions=${installationName}&cablelenght=${cableName}&src=${imageUrl}`

    //}, 6000)


  
  const download = async () => {
    try {

      var node = document.querySelector("#svg-holder svg");
      const old_height=node.getAttribute("height")
      node.setAttribute("height","600px")
      node.setAttribute("padding","10px")
      const blob = await domtoimage.toBlob(node,)
      node.setAttribute("height",old_height)
      node.setAttribute("padding","0px")
      // save img
      // saveAs(blob,"neon.png");
      return blob
    } catch (error) {

    }
    return false
  }


  return (
    <Fragment>
      <div className="card">
        <div className="price">
          <p>Gesamtsumme</p>
          <h1>€{totalPrice >> 0}</h1>
        </div>
        <div className="price-details">
          {/* <p>
            Base Price £ {setupPrices} + BASE COLOR £{props.colorPrice}{" "}
          </p>
          <p>+ HIGH SOLE £{props.colorPrice}</p> */}
          <div className="extra-price">
            <p>Inkl.20 % MwSt.</p>
            <p>Exkl.. Versand</p>
          </div>
          
        </div>
        <div className="container-btn">
          <Button text="In den Warenkorb" onClick={wooProductUpdate} />
        </div>
      </div>
      <Model ref={modelRef}>
        <h1>Product details</h1>
      </Model>
    </Fragment>
  );
};

export default CheckoutCard;