import firebase from "../../firebase/firebase"

export const setupPriceAction = () => async dispatch => {

    const ref = firebase.firestore().collection("setupprices")
    
    /* const setuprice = [];

        ref.onSnapshot((querySnapshot) => { 
            querySnapshot.forEach((doc) => {
                setuprice.push(doc.data())
            });
        })
 */
    try {
        dispatch({
            type: "SETUP_PRICE_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "SETUP_PRICE_SUCCESS",
                payload: items
            })         
        }) 

      /*  dispatch({
            type: "SETUP_PRICE_SUCCESS",
            payload: setuprice
        }) */
    } catch (error) {
        dispatch({
            type: "SETUP_PRICE_FAIL"
        })
    }
}