import React, { Children } from "react";
import Chevron from "../../../assets/IconLibrary/Chevron";

const Collapsed = ({children, handleOpen, id, icon, title }) => (

<div className={"accordion-btn"} onClick={handleOpen} data-id={id.toString()}>
        <div className="accordion-title">
          {icon && <img src={icon} alt="" />}
          <p onClick={handleOpen} data-id={id.toString()}>{title}</p>
        </div>

        <Chevron
          className={"accordion-icon"}
          width={13}
          height={8}
          color="#777"
          onClick={handleOpen}
          dataId={id.toString()}
        />
        {children}
</div>
 
);

export default Collapsed;


