import React from 'react'

const IconLibrary = props => {
    var icons = []

    icons = {
    open:
      'M11.8571 9.82581L7.85712 14.4975L11.8571 19.1692',
    close:
     'M12.1428 18.2856L16.1428 13.7142L12.1428 9.14279',
     up:
     'M6.91031 0.240309L0.0333555 9.04498C0.0152426 9.06799 0.00398624 9.09564 0.000879009 9.12475C-0.00222823 9.15387 0.00293971 9.18327 0.0157894 9.20958C0.0286391 9.23589 0.0486495 9.25805 0.0735222 9.2735C0.098395 9.28895 0.127121 9.29707 0.156403 9.29693L1.66617 9.29693C1.76187 9.29693 1.85367 9.25201 1.91226 9.17779L7.4025 2.14656L12.8927 9.17779C12.9513 9.25396 13.0431 9.29693 13.1388 9.29693L14.6486 9.29693C14.7795 9.29693 14.8517 9.14654 14.7716 9.04498L7.89469 0.240309C7.83631 0.165476 7.76164 0.104937 7.67636 0.0632998C7.59107 0.0216614 7.49741 1.87458e-05 7.4025 1.87499e-05C7.30759 1.87541e-05 7.21393 0.0216614 7.12864 0.0632998C7.04336 0.104937 6.96869 0.165476 6.91031 0.240309Z" fill="#626262',
    down:
    'M7.89486 9.05663L14.7718 0.251954C14.7899 0.228947 14.8012 0.201298 14.8043 0.172182C14.8074 0.143066 14.8022 0.113664 14.7894 0.0873528C14.7765 0.0610416 14.7565 0.0388884 14.7317 0.0234371C14.7068 0.00798568 14.6781 -0.000137139 14.6488 1.74477e-06L13.139 1.67878e-06C13.0433 1.6746e-06 12.9515 0.0449236 12.8929 0.119142L7.40267 7.15038L1.91244 0.119142C1.85384 0.04297 1.76205 1.18148e-06 1.66634 1.17729e-06L0.156577 1.1113e-06C0.0257177 1.10558e-06 -0.0465479 0.150391 0.0335302 0.251954L6.91049 9.05663C6.96886 9.13146 7.04353 9.192 7.12882 9.23364C7.21411 9.27527 7.30777 9.29692 7.40267 9.29692C7.49758 9.29692 7.59124 9.27527 7.67653 9.23364C7.76182 9.192 7.83649 9.13146 7.89486 9.05663Z',
    moon:
    'M8 5C7.9998 6.39064 8.41381 7.74983 9.18923 8.90421C9.96465 10.0586 11.0663 10.9559 12.3538 11.4816C13.6412 12.0073 15.0561 12.1376 16.4179 11.8559C17.7797 11.5742 19.0268 10.8933 20 9.9V10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0H10.1C9.43404 0.651125 8.90514 1.42896 8.54448 2.28768C8.18382 3.1464 7.99869 4.06862 8 5ZM2 10C1.99927 11.785 2.59553 13.5189 3.69389 14.926C4.79226 16.333 6.32963 17.3323 8.06141 17.7648C9.79319 18.1974 11.6199 18.0383 13.2508 17.313C14.8818 16.5876 16.2233 15.3377 17.062 13.762C15.5694 14.1136 14.0118 14.0781 12.5368 13.6587C11.0619 13.2394 9.7185 12.4501 8.63421 11.3658C7.54992 10.2815 6.76065 8.93814 6.34128 7.46318C5.92192 5.98821 5.88636 4.43056 6.238 2.938C4.95758 3.62014 3.88678 4.63766 3.14026 5.88164C2.39373 7.12562 1.99958 8.54921 2 10Z',
    sun:
    'M12 18C10.4087 18 8.88258 17.3679 7.75736 16.2426C6.63214 15.1174 6 13.5913 6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6C13.5913 6 15.1174 6.63214 16.2426 7.75736C17.3679 8.88258 18 10.4087 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18ZM12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16ZM11 1H13V4H11V1ZM11 20H13V23H11V20ZM3.515 4.929L4.929 3.515L7.05 5.636L5.636 7.05L3.515 4.93V4.929ZM16.95 18.364L18.364 16.95L20.485 19.071L19.071 20.485L16.95 18.364ZM19.071 3.514L20.485 4.929L18.364 7.05L16.95 5.636L19.071 3.515V3.514ZM5.636 16.95L7.05 18.364L4.929 20.485L3.515 19.071L5.636 16.95ZM23 11V13H20V11H23ZM4 11V13H1V11H4Z',
    checkmark:
    'M18.411 0L7.20128 10.3769L3.56569 7.01142L0 10.3122L3.63559 13.6777L7.22456 17L10.7903 13.6992L22 3.32235L18.411 0Z',
    top:
    'M7.07273 0.180923L0.262524 6.81029C0.244587 6.82762 0.23344 6.84844 0.230363 6.87036C0.227286 6.89228 0.232403 6.91442 0.245128 6.93423C0.257853 6.95404 0.277669 6.97072 0.302301 6.98235C0.326932 6.99399 0.35538 7.0001 0.384377 7L1.87949 7C1.97426 7 2.06517 6.96618 2.12319 6.91029L7.56015 1.61621L12.9971 6.91029C13.0551 6.96765 13.146 7 13.2408 7L14.7359 7C14.8655 7 14.9371 6.88676 14.8578 6.81029L8.04756 0.180923C7.98975 0.124578 7.9158 0.0789969 7.83134 0.0476458C7.74688 0.0162947 7.65413 -7.42465e-07 7.56015 -7.39103e-07C7.46616 -7.3574e-07 7.37341 0.0162947 7.28895 0.0476458C7.20449 0.0789969 7.13054 0.124578 7.07273 0.180923Z',
    bottom:
    'M8.04787 6.81908L14.8581 0.189706C14.876 0.172383 14.8872 0.151565 14.8902 0.129642C14.8933 0.10772 14.8882 0.0855819 14.8755 0.0657712C14.8628 0.0459605 14.8429 0.0292806 14.8183 0.0176466C14.7937 0.00601272 14.7652 -0.000103257 14.7362 1.31331e-06L13.2411 1.25982e-06C13.1463 1.25643e-06 13.0554 0.0338247 12.9974 0.0897067L7.56046 5.38379L2.12351 0.0897063C2.06549 0.0323537 1.97458 8.56745e-07 1.8798 8.53355e-07L0.384691 7.99865e-07C0.255102 7.95228e-07 0.183538 0.113235 0.262839 0.189705L7.07305 6.81908C7.13086 6.87542 7.2048 6.921 7.28926 6.95235C7.37372 6.9837 7.46647 7 7.56046 7C7.65445 7 7.7472 6.9837 7.83166 6.95235C7.91612 6.921 7.99006 6.87542 8.04787 6.81908Z',
    cable:
    'M30.8006 32.6667L32.8667 38.3262C33.5609 40.229 35.8639 41.2743 38.0118 40.6537C40.1555 40.0412 41.3315 37.9954 40.6373 36.0926L32.2665 13.1851C31.4621 10.9719 29.5103 9.2365 27.0194 8.52192C22.7319 7.28875 18.1218 9.37533 16.7294 13.181L8.37083 36.0885C7.67667 37.9954 8.85267 40.0371 10.9964 40.6577C13.1402 41.2703 15.4473 40.229 16.1414 38.3262L18.2035 32.6667H30.8006ZM27.8197 24.5H21.1843L24.5 15.4187L27.8197 24.5ZM8.16667 0H40.8333C42.9993 0 45.0765 0.860414 46.608 2.39196C48.1396 3.92351 49 6.00073 49 8.16667V40.8333C49 42.9993 48.1396 45.0765 46.608 46.608C45.0765 48.1396 42.9993 49 40.8333 49H8.16667C6.00073 49 3.92351 48.1396 2.39196 46.608C0.860414 45.0765 0 42.9993 0 40.8333V8.16667C0 6.00073 0.860414 3.92351 2.39196 2.39196C3.92351 0.860414 6.00073 0 8.16667 0Z',
    /* backgound:
    'M26.0001 51.8333C11.7659 51.8333 0.166748 40.2342 0.166748 26C0.166748 11.7658 11.7659 0.166656 26.0001 0.166656C40.2343 0.166656 51.8334 10.6033 51.8334 23.4167C51.8334 31.9675 44.8843 38.9167 36.3334 38.9167H31.7609C31.0376 38.9167 30.4692 39.485 30.4692 40.2083C30.4692 40.5183 30.5984 40.8025 30.8051 41.0608C31.8642 42.275 32.4584 43.7992 32.4584 45.375C32.4584 47.0878 31.778 48.7306 30.5668 49.9417C29.3556 51.1529 27.7129 51.8333 26.0001 51.8333ZM26.0001 5.33332C14.6076 5.33332 5.33342 14.6075 5.33342 26C5.33342 37.3925 14.6076 46.6667 26.0001 46.6667C26.7234 46.6667 27.2917 46.0983 27.2917 45.375C27.2837 45.0399 27.1553 44.719 26.9301 44.4708C25.8709 43.2825 25.3026 41.7583 25.3026 40.2083C25.3026 38.4955 25.983 36.8528 27.1942 35.6416C28.4054 34.4304 30.0481 33.75 31.7609 33.75H36.3334C42.0426 33.75 46.6668 29.1258 46.6668 23.4167C46.6668 13.445 37.3926 5.33332 26.0001 5.33332Z', */
    text:
    'M0 12.5H7.14286V6.25H18.7571L9.57143 43.75H0V50H28.5714V43.75H20.5286L29.7143 6.25H42.8571V12.5H50V0H0V12.5Z',
    installation:
    'M4.38191 0L3.3951 0.931057L0.930718 3.39634L0 4.38351L0.711622 5.53374L4.38191 11.6707L4.87444 12.5474H10.0258L17.0369 19.5066C10.7725 25.8013 2.80267 33.8004 2.52223 34.0827C-0.224354 36.8285 -0.229612 41.3277 2.57481 43.9456C5.31438 46.6493 9.77867 46.7195 12.3815 43.9456L12.4341 43.8895L23.2241 33.0411L34.0702 43.9438L34.2332 44.0543C36.9851 46.6493 41.3617 46.6844 43.9295 43.9438V43.8912H43.9821C46.6674 41.1436 46.6954 36.6777 43.9295 34.0809L43.8735 34.0283L34.7275 24.9317C40.8815 24.3565 45.7016 19.1577 45.791 12.877H45.8453C45.8523 12.8419 45.8453 12.8016 45.8453 12.7665V12.7122C46.0171 10.6852 45.5789 8.74598 44.5307 7.06797L43.3283 5.20761L35.1657 13.3715L32.7013 10.7957L41.027 2.46704L38.6169 1.48163C37.0796 0.805059 35.4202 0.450032 33.7407 0.438351C27.0031 0.438351 21.4714 5.97209 21.4714 12.7122C21.4714 13.4451 21.6291 14.0816 21.7448 14.7391C20.9788 15.5071 20.4039 16.1366 19.4995 17.0413L12.5428 10.0821V4.87622L11.6664 4.38351L5.53173 0.711882L4.38191 0ZM33.7407 3.94516C33.9861 3.94516 34.1579 4.08894 34.398 4.10998L27.7708 10.7396L28.975 12.0003L33.9055 17.0957L35.1096 18.3564L42.0663 11.3971C42.1014 11.8004 42.3889 12.095 42.3398 12.5474V12.7122C42.3398 17.5411 38.4031 21.4792 33.576 21.4792C32.9327 21.4792 32.1527 21.3284 31.2763 21.1496L30.3439 20.9865L29.6866 21.644L9.91539 41.4785H9.8593V41.5346C8.61484 42.897 6.43615 42.9303 4.98486 41.4785V41.4242H4.92877C3.56688 40.1793 3.53357 37.9998 4.98486 36.548C5.64916 35.8817 18.5127 22.903 24.8121 16.603L25.5255 15.8911L25.2503 14.9039C25.084 14.1847 24.9923 13.4502 24.9769 12.7122C24.9769 7.88331 28.9136 3.94516 33.7407 3.94516ZM4.92877 4.38351L9.03725 6.90491V8.65831L8.65515 9.04055H6.90239L4.38191 4.93233L4.92877 4.38351ZM30.563 25.6979L41.4652 36.548V36.6023H41.5177C42.8814 37.8472 42.9147 40.0267 41.4652 41.4785H41.4091V41.5346C40.1646 42.897 37.9859 42.9303 36.5346 41.4785L25.6885 30.5759L30.563 25.6979Z',

  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      fill="none"
      className={props.className}
      onClick={props.onClick}
    >
      <path
        d={icons[props.name]}
        stroke={props.color}
        fill={props.fill}
        //stroke-width="2"
        //stroke-linecap="square"
        onClick={props.onClick}
        
      />
    </svg>

  )
}

export default IconLibrary
