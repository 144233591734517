import React from "react"
import { Spinner } from "../../../assets/ImageLibrary"
import { useSelector} from "react-redux"


const PageLoader = () => {

    const spinner = useSelector((state => state.loadingPage))
 console.log("LOADING =====>", !spinner.loading);
    if(!spinner.loading) return null;

    return(
        <div className="loader-container">
            <div className="loader">
                <img src={Spinner}/>
            </div>
        </div>
    )
}

export default PageLoader