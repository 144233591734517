import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

const defaultState = {
    fontFamily: "",
    src: "",
    fontName: ""
}


export const getFonts = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.FONT_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FONT_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the fonts"
            }
        case ActionTypes.FONT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updateFontFamily = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_FONT_FAMILY:
            return {
                ...state,
                fontFamily: payload.fontFamily,
                src: payload.src
            }
        case ActionTypes.UPDATE_FONT_NAME:
            return {
                ...state,
                fontName: payload.fontName,
            }
        default:
            return state
    }
}