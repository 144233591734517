import React from "react"
import Chevron from "../../../assets/IconLibrary/Chevron";
import IconLibrary from "../../../assets/IconLibrary/IconLibrary";
import Option from "../../../assets/IconLibrary/Option";
const TabTop = ({ activeTab, label, onClick, icon, name, width, height}) => {

    const handdleNameClick = () => {
        onClick(name);
    }

    let className = "tab-list-item_top";

    if (activeTab === name) {
      className += " tab-list-active_top";
    }

    return (
      <li className={className} onClick={handdleNameClick}>
         
          {name === "backgound" ? (
            <Option
                width={width} 
                height={height} 
                fill="#FFFFFF" 
                onClick={handdleNameClick}
            />
          ):(
          <IconLibrary
            name={name} 
            width={width} 
            height={height} 
            fill="#FFFFFF" 
            onClick={handdleNameClick}
          />
          )}
      </li>
    );
}

export default TabTop