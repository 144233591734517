import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

const defaultState = {
    cable: "",
}

const defaultStateName = {
    cableName: ""
}

export const getCables = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.CABLE_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.CABLE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the fonts"
            }
        case ActionTypes.CABLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updateCable = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_CABLE:
            return {
                ...state,
                cable: payload.color,
            }
        default:
            return state
    }
}

export const updateCableName = (state = defaultStateName, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_CABLE_NAME:
            return {
                ...state,
                cableName: payload.cableName,
            }
        default:
            return state
    }
}