import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    menuState: ""
}

export const toggleMenu = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.TOGGLE_MENU:
            return {...state, menuState: payload.menuState }
        default:
            return state
    }
}