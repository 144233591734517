import React, { useState } from "react";
import { createUseStyles } from "react-jss";



const InstallationSelect = (props) => {
  const [value, setValue] = useState(
    props.initialValue ? props.initialValue : ""
  );

  const handleChange = (event) => {
    let element = event.target;
    let value = element.value;
    let name = element.name;

    if (props.getValue) {
      props.getValue(name, value);
    }
    setValue(value);
  };


  //console.log(classes)

  return (
    <div className="multiselect-installation">
      <label htmlFor={props.id} className="custom-multiselect-installation">
      <input
        type="radio"
        id={props.id}
        name={props.name}
        onChange={(e) => handleChange (e)}
        value={value}
        className="custom-installation"
        checked={props.checked}
      />
      <span className={"text-multiselect-installation"}>
          <p style={props.style}>{props.label}</p>
      </span>
        
      </label>
    </div>
  );
};

export default InstallationSelect;