
import firebase from 'firebase/app';
import "firebase/firestore";
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDOIlNrBuaiYMzEeYH7WIBgOGG-JQzA7-Q",
    authDomain: "web-app-front-end.firebaseapp.com",
    databaseURL: "https://web-app-front-end.firebaseio.com",
    projectId: "web-app-front-end",
    storageBucket: "web-app-front-end.appspot.com",
    messagingSenderId: "690390098120",
    appId: "1:690390098120:web:9ada460112b657817985fd",
    measurementId: "G-2YXBCP2SSB"
  };

  firebase.initializeApp(firebaseConfig);
  const storage = firebase.storage();
  //firebase.firestore().settings({ experimentalForceLongPolling: true });
  export { storage,  firebase as default}