import firebase from "../../firebase/firebase"

export const optionList = () => async dispatch => {

    const ref = firebase.firestore().collection("options")
    
    /* const options = [];

        ref.onSnapshot((querySnapshot) => { 
            querySnapshot.forEach((doc) => {
                options.push(doc.data())
            });
        }) */

    try {

        dispatch({
            type: "OPTION_LIST_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "OPTION_LIST_SUCCESS",
                payload: items
            })         
        }) 

        /* dispatch({
            type: "OPTION_LIST_SUCCESS",
            payload: options
        }) */
        
    } catch (error) {
        dispatch({
            type: "OPTION_LIST_FAIL"
        })
    }
}