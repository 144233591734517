import React, { useState, forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";



const CheckoutModel = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);

    useImperativeHandle(ref, () => {
        return{
            openModel: () => open(),
            close: () => close()
        }
    });
  
    const open = () => {
      setDisplay(true);
    };
  
    const close = () => {
      setDisplay(false);
    };
  
    if (display) {
      return ReactDOM.createPortal(
        <div className="model-wrapper">
          <div onClick={close} className="model-close"></div>
          <div className="model-box">{props.children}</div>
        </div>, document.getElementById("model-root"));
    }
    return null;
  });
export default CheckoutModel;
