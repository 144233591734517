import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

export const getFiles = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.FILES_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FONT_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the file"
            }
        case ActionTypes.FONT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}