import React, { useState } from "react";
import IconLibrary from "../../../assets/IconLibrary/IconLibrary";
import { createUseStyles } from "react-jss";



const useStyles = createUseStyles({
    backgroundColors: {
      backgroundColor: props => [[props.color], '!important']
    }
})
const ColorSelect = (props) => {
  const [value, setValue] = useState(
    props.initialValue ? props.initialValue : ""
  );

  const handleChange = (event) => {
    let element = event.target
    let value = element.value
    let name = element.name

    if (props.getValue) {
      props.getValue(name, value)
    }
    setValue(value)
  };

  const classes = useStyles(props)

  return (
    <div className={`background-colors ${classes.backgroundColors}`}>
      <label htmlFor={props.id} className="custom-background-color">
        <input
          type="radio"
          id={props.id}
          name={props.name}
          onChange={(e) => handleChange(e)}
          value={value}
          checked={props.checked}
        />
        <span className="btn-background-color">
          <IconLibrary
            width={22}
            height={17}
            fill="white"
            name="checkmark"
            className="background-checkmark-btn"
          />
        </span>
      </label>
    </div>
  );
};

export default ColorSelect;
