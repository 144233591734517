import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

const defaultState = {
    color: "",
}

const defaultStatePvc = {
    pvcName: "",
}

export const getPVC = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.PVC_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.PVC_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the pvc colors"
            }
        case ActionTypes.PVC_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updatePvc = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_PVC:
            return {
                ...state,
                color: payload.color,
            }
        default:
            return state
    }
}

export const updatePvcName = (state = defaultStatePvc, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_PVC_NAME:
            return {
                ...state,
                pvcName: payload.pvcName,
            }
        default:
            return state
    }
}