export const ActionTypes = {
    UPDATE_INPUT: 'UPDATE_INPUT',
    TOGGLE_MENU: 'TOGGLE_MENU',
    FONT_LIST_LOADING: "FONT_LIST_LOADING",
    FONT_LIST_SUCCESS: "FONT_LIST_SUCCESS",
    FONT_LIST_FAIL: "FONT_LIST_FAIL",
    UPDATE_FONT_FAMILY: "UPDATE_FONT_FAMILY",

    COLOR_LIST_LOADING: " COLOR_LIST_LOADING",
    COLOR_LIST_SUCCESS: "COLOR_LIST_SUCCESS",
    COLOR_LIST_FAIL: "COLOR_LIST_FAIL",

    UPDATE_COLOR: "UPDATE_COLOR",
    UPDATE_PLEXES: "UPDATE_PLEXES",
    UPDATE_PVC: "UPDATE_PVC",
    UPDATE_MORE_OPTION: "UPDATE_MORE_OPTION",


    UPDATE_SIZE: "UPDATE_SIZE",

    BACKGROUND_LIST_LOADING: "BACKGROUND_LIST_LOADING",
    BACKGROUND_LIST_SUCCESS: "BACKGROUND_LIST_SUCCESS",
    BACKGROUND_LIST_FAIL: "BACKGROUNDLIST_FAIL",

    PVC_LIST_LOADING: "PVC_LIST_LOADING",
    PVC_LIST_SUCCESS: "PVC_LIST_SUCCESS",
    PVC_LIST_FAIL: "PVC_LIST_FAIL",

    INSTALLATION_LIST_LOADING: "INSTALLATION_LIST_LOADING",
    INSTALLATION_LIST_SUCCESS: "INSTALLATION_LIST_SUCCESS",
    INSTALLATION_LIST_FAIL: "INSTALLATION_LIST_FAIL",

    CABLE_LIST_LOADING: "CABLE_LIST_LOADING",
    CABLE_LIST_SUCCESS: "CABLE_LIST_SUCCESS",
    CABLE_LIST_FAIL: "CABLE_LIST_FAIL",

    SUN_THEME: 'SUN_THEME',
    MOON_THEME: 'MOON_THEME',


    SWITCH_THEME: "SWITCH_THEME",


    OPTION_LIST_LOADING: "OPTION_LIST_LOADING",
    OPTION_LIST_SUCCESS: "OPTION_LIST_SUCCESS",
    OPTION_LIST_FAIL: "OPTION_LIST_FAIL",


    // output 
    UPDATE_SVG: "UPDATE_SVG",
    UPDATE_WIDTH: "UPDATE_WIDTH",
    UPDATE_HEIGHT: "UPDATE_HEIGHT",
    UPDATE_FACTORS: "UPDATE_FACTORS",
    SET_MIN_MAX_DIMS: "SET_MIN_MAX_DIMS",
    RESET_SVG_TO_MIN_SIZE: "RESET_SVG_TO_MIN_SIZE",
    UPDATE_PRICE: "UPDATE_PRICE",
    UPDATE_INSTALLATION: "UPDATE_INSTALLATION",
    UPDATE_CABLE: "UPDATE_CABLE",
    UPDATE_FONT_NAME: "UPDATE_FONT_NAME",


    SAVE_SETUP_PRICES: "SAVE_SETUP_PRICES",

    SETUP_PRICE_LOADING: "SETUP_PRICE_LOADING",
    SETUP_PRICE_SUCCESS: "SETUP_PRICE_SUCCESS",
    SETUP_PRICE_FAIL: "SETUP_PRICE_FAIL",

    UPDATE_WOO_PRODUCT: "UPDATE_WOO_PRODUCT",
    UPDATE_WOO_PRODUCT_SUCCESS: "UPDATE_WOO_PRODUCT_SUCCESS",
    UPDATE_WOO_PRODUCT_ERROR: "UPDATE_WOO_PRODUCT_ERROR",

    GET_WOO_PRODUCT: "GET_WOO_PRODUCT",
    GET_WOO_PRODUCT_SUCCESS: "GET_WOO_PRODUCT_SUCCESS",
    GET_WOO_PRODUCT_ERROR: "GET_WOO_PRODUCT_ERROR",

    ADD_TO_CART_QTY: "ADD_TO_CART_QTY",
    ADD_TO_CART_OPTIONS: "ADD_TO_CART_OPTIONS",

    UPDATE_COLOR_NAME: "UPDATE_COLOR_NAME",
    UPDATE_PLEXES_NAME: "UPDATE_PLEXES_NAME",
    UPDATE_PVC_NAME: "UPDATE_PVC_NAME",
    UPDATE_INSTALLATION_NAME: "UPDATE_INSTALLATION_NAME",
    UPDATE_CABLE_NAME: "UPDATE_CABLE_NAME",

    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",

    VALIDATE_INPUT: "VALIDATE_INPUT",
    SHOW_ERROR: "SHOW_ERROR",

    FILES_LIST_LOADING: "FILES_LIST_LOADING",
    FILES_LIST_SUCCESS: "FILES_LIST_SUCCESS",
    FILES_LIST_FAIL: "FILES_LIST_FAIL",

    UPDATE_SETUP_NAME: "UPDATE_SETUP_NAME",
    UPDATE_SETUP_OPTION: "UPDATE_SETUP_OPTION"


}