import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setupPriceAction } from "../../../redux/actions/setupPriceAction";
import { ActionTypes } from "../../../redux/constant";

const Switch = (props) => {
  const [valueInput, setValueInput] = useState();
  const [indexValue, setIndexValue] = useState();
  const [setupPriceState, setSetupPriceState] = useState();
  const getSetupPrice = useSelector((state) => state.getSetupPrice);
  
  const [buttonValue, setButtonValue] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    FetchData();
  }, []);

  useEffect(() => {
    _handleChange();
  }, []);

  const FetchData = () => {
    dispatch(setupPriceAction());
  };

  useEffect(() => {
    _handleChange(null,0)
    
  }, [getSetupPrice])

  const _handleChange = (event,custom_index) => {
    const index = custom_index || event?.target?.checked ? 0 : 1;

    if (!getSetupPrice.data.length) {
      return 0;
    }
    const setupData = getSetupPrice.data[index ?? 0];
    const { setupPriceName: name } = setupData;
    
      dispatch({
        type: ActionTypes.UPDATE_SETUP_NAME,
        payload: {
          setupName: name,
        },
      });

    if (getSetupPrice?.data.length) {
      dispatch({
        type: ActionTypes.UPDATE_FACTORS,
        payload: {
          factor_name: "setupPrices",
          value: getSetupPrice.data[index].price,
        },
      });
    }

 
   /*  dispatch({
      type: ActionTypes.UPDATE_SETUP_NAME,
      payload: {
        setupName: getSetupPrice.data[index].setupPriceName,
      },
    }); */
  };

console.log("Setup prices ======>", getSetupPrice);
  return (
    <div className="switch-container">
      <input
        className="switch-toggle"
        type="checkbox"
        checked={buttonValue}
        onClick={() => setButtonValue((s) => !s)}
        // value={getSetupPrice.price}
        onChange={_handleChange}
      />
      {getSetupPrice.data.map((neon, index) => (
        <Fragment key={index}>
          <label
            htmlFor=""
            className={index === 1 ? "onbtn" : "offbtn"}
            // onClick={()=>_handleChange(index)}
          >
            {neon.setupPriceName}
          </label>
        </Fragment>
      ))}
    </div>
  );
};

export default Switch;