import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import LandingLayoutRoute from "./layouts/SidebarLayout"
import ViewBox from "./pages/ViewBox/ViewBox";

function App() {
  const [isAuth, setIsAuth] = useState(true);
  return (
    <Router>
      <LandingLayoutRoute
        exact
        path="/"
        component={ViewBox}
        isAuth={isAuth}
        setIsAuth={setIsAuth}
      />
    </Router>
   
  );
}

export default App;
