import React, { useState, useEffect, useRef } from "react";
import {
  text,
  color,
  background,
  installation,
  textTab,
  backgroundTab,
  cableTab,
  installationTab,
} from "../../../assets/ImageLibrary";
import Accordions from "../../FormElement/Accordion/Accodrions";
import InputText from "../../FormElement/InputText/InputText";
import ColorSelect from "../../FormElement/ColorSelect/ColorSelect";
import RangeInput from "../../FormElement/RangeInput/RangeInput";
import MultiSelect from "../../FormElement/MultiSelect/MultiSelect";
import IconLibrary from "../../../assets/IconLibrary/IconLibrary";
import CheckoutCard from "../../Cards/CheckoutCard/CheckoutCard";
import BackgroundSelect from "../../FormElement/BackgroundSelect/BackgroundSelect";
import Switch from "../../FormElement/Switch/Switch";
import SwitchItem from "../../FormElement/SwitchItem/SwitchItem";
import InstallationSelect from "../../FormElement/InstallationSetect/InstallationSelect";
import { fontsList } from "../../../redux/actions/fontsList";
import { colorsList } from "../../../redux/actions/colorsList";
import { backgroundList } from "../../../redux/actions/backgroundList";
import { pvcList } from "../../../redux/actions/pvcList";
import { installationList } from "../../../redux/actions/installationList";
import { cablesList } from "../../../redux/actions/cablesList";
import _, { first } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import CarouselOption from "../../CarouselOption/CarouselOption";
import { setupPriceAction } from "../../../redux/actions/setupPriceAction";
import { optionList } from "../../../redux/actions/optionList";
import { ActionTypes } from "../../../redux/constant";
import SkeletonElement from "../../FormElement/Skeleton/SkeletonElement";
import SkeletonSidebar from "../../FormElement/Skeleton/SkeletonSidebar";
import TabsTop from "../../FormElement/TabsTop/TabsTop"

const Sidebar = (props) => {
  const [inputValue, setInputValue] = useState({ text: "" });
  const [inputValuePex, setInputValuePex] = useState("");
  const [inputValuePvc, setInputValuePvc] = useState("");
  const [inputValueInstallation, setInputValueInstallation] = useState("");
  const [inputValueCable, setInputValueCable] = useState("");
  const [inputValueFont, setInputValueFont] = useState("");
  const [switchValue, setSwitchValue] = useState(false);
  const [fontFamily, setFontFamily] = useState("");
  const [rangeValue, setRangeValue] = useState(90);
  const [activeState, setActiveState] = useState("");
  const [menuState, setMenuState] = useState("open");
  const [rotateState, setRotateState] = useState("sidebar-icon");
  const content = useRef(null);
  const inputRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    FetchData();
  }, []);

  const FetchData = () => {
    dispatch(fontsList());
    dispatch(colorsList());
    dispatch(backgroundList());
    dispatch(pvcList());
    dispatch(installationList());
    dispatch(cablesList());
    dispatch(setupPriceAction());
    dispatch(optionList());
    //dispatch(factorsList());
  };

  const setFont = useSelector((state) => state.updateFontFamily);

  const colorList = useSelector((state) => state.getColors);

  const backgroundsList = useSelector((state) => state.getBackground);

  const pvcColors = useSelector((state) => state.getPVC);

  const installationOptions = useSelector(
    (state) => state.getInstallationOption
  );

  const cableOption = useSelector((state) => state.getCables);

  const fontList = useSelector((state) => state.getFonts);

  const factors = useSelector((state) => state.factors);

  const [minWidth, setMinWidth] = useState(1);

  const getSetupPrice = useSelector((state) => state.getSetupPrice);

  const options = useSelector((state) => state.getOption);

  const [sizeLimits, setSizeLimits] = useState({
    minHeight: 0,
    maxHeight: 0,
  });

  useEffect(() => {
    if (!fontList.loading && !fontList.errorMsg && fontList.data.length) {
      _handleFontFamilyChange(0);
      _handleFontNameChange(0);
    }
  }, [fontList]);

  useEffect(() => {
    if (!colorList.loading && !colorList.errorMsg && colorList.data.length) {
      _handleChangeColor(0);
    }
  }, [colorList]);

  useEffect(() => {
    if (
      !cableOption.loading &&
      !cableOption.errorMsg &&
      cableOption.data.length
    ) {
      _handleChangeConnectoinCable(0);
    }
  }, [cableOption]);

  useEffect(() => {
    if (
      !installationOptions.loading &&
      !installationOptions.errorMsg &&
      installationOptions.data.length
    ) {
      _handleChangeInstallation(0);
    }
  }, [installationOptions]);

  useEffect(() => {
    if (!pvcColors.loading && !pvcColors.errorMsg && pvcColors.data.length) {
      _handleChangePvcColor(0);
    }
  }, [pvcColors]);

  useEffect(() => {
    if (
      !backgroundsList.loading &&
      !backgroundsList.errorMsg &&
      backgroundsList.data.length
    ) {
      _handleChangePlexiglasColor(0);
    }
  }, [backgroundsList]);

  // useEffect(() => {
  //   if (!fontList.loading && !fontList.errorMsg && fontList.data.length) {
  //     _handleFontFamilyChange(0);
  //   }
  // }, [fontList]);

  useEffect(() => {
    /* const valid = inputRef.current.validate();
    console.log("Validation 2 =====>", valid);

    dispatch({
      type: ActionTypes.VALIDATE_INPUT,
      payload: {
        validate: valid,
      },
    }); */

    dispatch({
      type: ActionTypes.ADD_TO_CART_OPTIONS,
      payload: {
        text: inputValue.text.length === 0 ? "Hello people" : inputValue.text,
      },
    });
  }, [inputValue, dispatch]);

  useEffect(() => {
    const value = options?.data[0]?.optionName;
    const price = options?.data[0]?.factorPrice;

    dispatch({
      type: ActionTypes.UPDATE_MORE_OPTION,
      payload: {
        optionName: value,
      },
    });

    dispatch({
      type: ActionTypes.UPDATE_FACTORS,
      payload: {
        factor_name: "mo",
        value: price,
      },
    });
  }, [options]);

  const toggleMenu = () => {
    setActiveState(activeState === "" ? "active" : "");

    setMenuState(activeState === "active" ? "open" : "close");

    setRotateState(
      activeState === "active" ? "sidebar-icon" : "sidebar-icon rotate"
    );
    dispatch({
      type: ActionTypes.TOGGLE_MENU,
      payload: {
        menuState: activeState === "active" ? "open" : "close",
      },
    });
  };

  const _handleInputChange = (value) => {
    setInputValue({ ...inputValue, text: value });

    dispatch({
      type: ActionTypes.UPDATE_INPUT,
      payload: {
        text: value,
      },
    });

    dispatch({
      type: ActionTypes.ADD_TO_CART_OPTIONS,
      payload: {
        text: value,
      },
    });

    const valid = inputRef.current.validate();

    dispatch({
      type: ActionTypes.VALIDATE_INPUT,
      payload: {
        validate: valid,
      },
    });

    //console.log(value);
  };

  // Colors
  const _handleChangeColor = (index) => {
    if (!colorList.data.length) {
      return 0;
    }
    const colorData = colorList.data[index ?? 0];
    const { hexCode: value, colorName: color } = colorData;
    setInputValue({ ...inputValue, color: value });

    dispatch({
      type: ActionTypes.UPDATE_COLOR,
      payload: {
        color: value,
      },
    });
    dispatch({
      type: ActionTypes.UPDATE_FACTORS,
      payload: {
        factor_name: "cl",
        value: colorData.factorPrice,
      },
    });
    dispatch({
      type: ActionTypes.UPDATE_COLOR_NAME,
      payload: {
        colorName: colorData.colorName,
      },
    });
  };

  // Plexiglas Color
  const _handleChangePlexiglasColor = (index) => {
    if (!backgroundsList.data.length) {
      return 0;
    }
    const plexiglasColorData = backgroundsList.data[index ?? 0];
    const { hexCode: value, plexiColorName: plexi } = plexiglasColorData;
    setInputValuePex({ ...inputValuePex, color: value });

    dispatch({
      type: ActionTypes.UPDATE_PLEXES,
      payload: {
        color: value,
      },
    });
    dispatch({
      type: ActionTypes.UPDATE_FACTORS,
      payload: {
        factor_name: "plx",
        value: plexiglasColorData.factorPrice,
      },
    });

    dispatch({
      type: ActionTypes.UPDATE_PLEXES_NAME,
      payload: {
        plexesName: plexi,
      },
    });
  };

  // PVC Color
  const _handleChangePvcColor = (index) => {
    if (!pvcColors.data.length) {
      return 0;
    }
    const pvcColorData = pvcColors.data[index ?? 0];
    const { hexCode: value } = pvcColorData;
    setInputValuePvc({ ...inputValuePvc, color: value });

    dispatch({
      type: ActionTypes.UPDATE_PVC,
      payload: {
        color: value,
      },
    });
    dispatch({
      type: ActionTypes.UPDATE_FACTORS,
      payload: {
        factor_name: "pvc",
        value: pvcColorData.factorPrice,
      },
    });
    dispatch({
      type: ActionTypes.UPDATE_PVC_NAME,
      payload: {
        pvcName: pvcColorData.colorName,
      },
    });
  };

  // Installation Option
  const _handleChangeInstallation = (index) => {
    if (!installationOptions.data.length) {
      return 0;
    }
    const installationData = installationOptions.data[index ?? 0];
    const { installationOption: value } = installationData;
    setInputValueInstallation({ ...inputValueInstallation, option: value });

    dispatch({
      type: ActionTypes.UPDATE_INSTALLATION,
      payload: {
        option: value,
      },
    });
    dispatch({
      type: ActionTypes.UPDATE_FACTORS,
      payload: {
        factor_name: "io",
        value: installationData.factorPrice,
      },
    });

    dispatch({
      type: ActionTypes.UPDATE_INSTALLATION_NAME,
      payload: {
        installationName: installationData.installationOption,
      },
    });
  };

  // Connectoin Cable
  const _handleChangeConnectoinCable = (index) => {
    if (!cableOption.data.length) {
      return 0;
    }
    const cableData = cableOption.data[index ?? 0];
    const { cableName: value } = cableData;
    setInputValueCable({ ...inputValueCable, cable: value });

    dispatch({
      type: ActionTypes.UPDATE_CABLE,
      payload: {
        cable: value,
      },
    });
    dispatch({
      type: ActionTypes.UPDATE_FACTORS,
      payload: {
        factor_name: "ic",
        value: cableData.price,
      },
    });

    dispatch({
      type: ActionTypes.UPDATE_CABLE_NAME,
      payload: {
        cableName: cableData.cableName,
      },
    });
  };

  // Fonts
  const _handleFontNameChange = (index) => {
    if (!fontList.data.length) {
      return 0;
    }
    const selectedFont = fontList.data[index];

    const { fontName: value } = selectedFont;
    setInputValueFont({ ...inputValueFont, name: value });

    dispatch({
      type: ActionTypes.UPDATE_FONT_NAME,
      payload: {
        fontName: value,
      },
    });

    dispatch({
      type: ActionTypes.UPDATE_FACTORS,
      payload: {
        factor_name: "lm",
        value: selectedFont.lm,
      },
    });

    dispatch({
      type: ActionTypes.ADD_TO_CART_OPTIONS,
      payload: {
        font: value,
      },
    });

    dispatch({
      type: ActionTypes.ADD_TO_CART_OPTIONS,
      payload: {
        text: inputValue.text.length === 0 ? "Hello people" : inputValue.text,
      },
    });
  };

  const _handleChangeRange = (event) => {
    setRangeValue(event.target.value);

    dispatch({
      type: "UPDATE_HEIGHT",
      payload: {
        height: event.target.value >> 0,
      },
    });
  };

  const _handleFontFamilyChange = (fontIndex) => {
    const selectedFont = fontList.data[fontIndex];
    const newFontFamily = selectedFont.fontName;
    const src = selectedFont.url;
    const { minHeight, maxHeigh: maxHeight, minWidth } = selectedFont;

    setSizeLimits({ minHeight, maxHeight });
    if (fontFamily === newFontFamily) {
      return 0;
    }

    setFontFamily(newFontFamily);
    dispatch({
      type: "UPDATE_FONT_FAMILY",
      payload: {
        fontFamily: newFontFamily,
        src,
      },
    });

    /* dispatch({
      type: ActionTypes.UPDATE_FONT_NAME,
      payload: {
        fontFamily: newFontFamily,
      },
    }); */

    dispatch({
      type: ActionTypes.SET_MIN_MAX_DIMS,
      payload: { width: minWidth, height: minHeight },
    });
    setRangeValue(minHeight);
    setMinWidth(minHeight);
    dispatch({
      type: "UPDATE_HEIGHT",
      payload: {
        height: minHeight,
      },
    });
  };

  const _handleSwitchChange = (e) => {
    setSwitchValue({ ...switchValue, [e.target.name]: e.target.checked });

    dispatch({
      type: ActionTypes.UPDATE_SETUP_OPTION,
      payload: {
        bgOption: e.target.checked,
      },
    });
  };

  const output = useSelector((state) => state.output);

  const handleWidthChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, "");
    dispatch({
      type: "UPDATE_HEIGHT",
      payload: {
        height: value >> 0,
      },
    });
  };
  const { height } = output;

  const checkSize = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, "");
    if (value > sizeLimits.maxHeight) {
      alert(
        `plz we put value between ${sizeLimits.minHeight}cm and ${sizeLimits.maxHeight}cm!`
      );
      dispatch({
        type: "UPDATE_HEIGHT",
        payload: {
          height: sizeLimits.maxHeight,
        },
      });
    }
    if (value < sizeLimits.minHeight) {
      alert(
        `plz we put value between ${sizeLimits.minHeight}cm and ${sizeLimits.maxHeight}cm!`
      );
      dispatch({
        type: "UPDATE_HEIGHT",
        payload: {
          height: sizeLimits.minHeight,
        },
      });
    }
  };

  return (
    <React.Fragment>
    <nav id="sidebar" className={`sidebar-menu ${!fontList.loading && 'skelton-bg'}`}>
       {/* {!fontList.loading && ( */}
         <React.Fragment>
         <div className="tb-configuration">
           <div className="configuration">

             <div>
          <TabsTop>
              <div  name="text" width="50" height="50">
              <Switch/>
              <h1 id="text-title">Gib deinen Text ein</h1>
              <hr className="tab-list__line"/>
              <InputText
                name={"text"}
                ref={inputRef}
                validation={"required"}
                initialValue={inputValue.text}
                getValue={_handleInputChange}
                placeholder="Hello Poeple"
                fontFamily={setFont.fontFamily}
              />

              <div className="typeface-container">
                <h1 id="text-title">Schriftart wählen</h1>
                <hr className="tab-list__line"/>
                {fontList.loading ? ( 
                  <div className="multiselect-scrollbar">
                  <div className="multiselect-container">
                    <div className="loading-section">Loading....</div> 
                  </div>
              </div>
                ):(
                <div className="multiselect-scrollbar">
                    <div className="multiselect-container">
                      {fontList.data.map((font, fontIndex) => {
                          /* return font.fontFile.map((fontFace, index) => { */
                          return (
                            <MultiSelect
                              onClick={() => _handleFontFamilyChange(fontIndex)}
                              id={font.id}
                              name={"typograph"}
                              key={`${fontIndex}`}
                              style={{
                                fontFamily: `${font.fontName}`,
                                //fontSize: "1.2em",
                              }}
                              //label={fontFace.name.split(".ttf")}
                              label={font.fontName}
                              initialValue={font.fontName}
                              getValue={() => _handleFontNameChange(fontIndex)}
                              checked={inputValueFont.name === font.fontName}
                            />
                          );
                        /*  }); */
                      })}
                    </div>
                </div>
                )}
              </div>
              
              </div>
              <div name="backgound" width="52" height="52">
              <h1 id="text-title">Neon Farbe Wählen</h1>
              <hr className="tab-list__line"/>
              <div className="background-color-container" style={{marginTop: '16px'}}>
                {colorList.data.map((color, index) => {
                  return (
                    <ColorSelect
                      key={index}
                      id={color.id}
                      color={color.hexCode}
                      name={"color"}
                      initialValue={color.hexCode}
                      checked={inputValue.color === color.hexCode}
                      getValue={() => _handleChangeColor(index)}
                    />
                  );
                })}
              </div>
              </div>
              <div name="cable" width="49" height="49">
              <h1 id="text-title">Träger Art</h1>
              <hr className="tab-list__line"/>
              <div className="backgound-plexes" style={{marginTop: '16px'}}>
                <SwitchItem
                  labelOff="pvc"
                  labelOn="Acrylglas"
                  onChange={(e) => _handleSwitchChange(e)}
                  value={switchValue.isChekced}
                />

                <div
                  className={`background-plexes-content ${
                    switchValue[""] ? "active" : ""
                  }`}
                >
                  <h1 id="text-title">Acrylglas Farbe</h1>
                  <hr className="tab-list__line"/>
                  <div className="background-color-container">
                    {backgroundsList.data.map((backgound, index) => {
                      return (
                        <BackgroundSelect
                          key={index}
                          id={backgound.id}
                          color={backgound.hexCode}
                          name={"background"}
                          initialValue={backgound.hexCode}
                          //getValue={_handleChangeColor}
                          getValue={() => _handleChangePlexiglasColor(index)}
                          checked={inputValuePex.color === backgound.hexCode}
                          title={backgound.plexiColorName}
                        />
                      );
                    })}
                  </div>
                  <div className="more-option">
                      <h1 id="text-title">Mehr optionen</h1>
                      <hr className="tab-list__line"/>
                    <CarouselOption options={options} />
                  </div>
                </div>

                <div
                  className={`background-pvc-content ${
                    switchValue[""] ? "" : "active"
                  }`}
                >
                  <h1 id="text-title">PVC Hartschaum Farbe</h1>
                  <hr className="tab-list__line"/>
                  <div className="background-color-container" style={{marginTop: '16px'}}>
                    {pvcColors.data.map((pvc, index) => {
                      return (
                        <ColorSelect
                          id={pvc.id}
                          key={index}
                          color={pvc.hexCode}
                          name={"pvc"}
                          initialValue={pvc.hexCode}
                          getValue={() => _handleChangePvcColor(index)}
                          checked={inputValuePvc.color === pvc.hexCode}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              </div>
              <div name="installation" width="46" height="46">
              <div className="installation-container">
              <h1 id="text-title">Schutz wählen</h1>
                  <hr className="tab-list__line"/>
                <div className="multiselect-container" style={{marginTop: '16px'}}>
                  {installationOptions.data.map((option, index) => {
                    return (
                      <InstallationSelect
                        //onClick={() => _handleFontFamilyChange(fontIndex)}
                        getValue={() => _handleChangeInstallation(index)}
                        initialValue={option.installationOption}
                        id={option.id}
                        name={"installations"}
                        key={index}
                        label={option.installationOption}
                        checked={
                          inputValueInstallation.option ===
                          option.installationOption
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className="installation-container" style={{marginTop: '20px'}}>
              <h1 id="text-title">Installation Kabel</h1>
                  <hr className="tab-list__line"/>
                <div className="multiselect-container" style={{marginTop: '16px'}}>
                  {cableOption.data.map((cable, index) => {
                    return (
                      <InstallationSelect
                        getValue={() => _handleChangeConnectoinCable(index)}
                        initialValue={cable.cableName}
                        id={cable.id}
                        name={"cables"}
                        key={index}
                        label={cable.cableName}
                        checked={inputValueCable.cable === cable.cableName}
                      />
                    );
                  })}
                </div>
              </div>
              </div>
          </TabsTop> 
          </div>
          <div className="tb-range-size_card">  
          <RangeInput
                label="Grösse wählen"
                min={sizeLimits.minHeight}
                max={sizeLimits.maxHeight}
                step={1}
                initialValue={rangeValue}
                getValue={_handleChangeRange}
                rangeValue={rangeValue}
                widthValue={height}
                handleWidthChange={handleWidthChange}
                onBlur={checkSize}
              />
          <CheckoutCard colorPrice={0} />
          </div>
        </div>
        </div>
      </React.Fragment>
      
    </nav>
    
    
   {/*   {fontList.loading && (
      <SkeletonSidebar theme="dark"/>
    )} */}
    </React.Fragment>
  );
};

export default Sidebar;
