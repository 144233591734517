import { WooCommerce } from "../../WooAPI/API"


export const fetchUpdateWooProduct = (data) => async dispatch => {

    dispatch({
        type: "UPDATE_WOO_PRODUCT"
    })

    WooCommerce.post(`products`, data)
        .then((response) => {
            dispatch({
                type: "UPDATE_WOO_PRODUCT_SUCCESS",
                payload: data,
            })
        })
        .catch((error) => {

            dispatch({
                type: "UPDATE_WOO_PRODUCT_ERROR",
                payload: error.response
            })
        });
}
export const fetchGetWooProduct = () => async dispatch => {

    dispatch({
        type: "GET_WOO_PRODUCT"
    })

    WooCommerce.get(`products`)
        .then((response) => {
            dispatch({
                type: "GET_WOO_PRODUCT_SUCCESS",
                payload: response.data,
            })
        })
        .catch((error) => {

            dispatch({
                type: "GET_WOO_PRODUCT_ERROR",
                payload: error
            })
        });

}

export const showLoader = () => dispatch => {
    dispatch({
        type: "SHOW_LOADER"
    })
}

export const hideLoader = () => dispatch => {
    dispatch({
        type: "HIDE_LOADER"
    })
}

export const validationInput = () => dispatch => {
    dispatch({
        type: "VALIDATE_INPUT",
    })
}

export const validationInputError = (validate) => dispatch => {
    dispatch({
        type: "SHOW_ERROR",
        payload: validate
    })
}