import React from "react"

const Footer = () => {
    return(
        <footer id='footer' className='footer-container'>
            <div className="footer-text">All Rights Reserved @2020</div>
        </footer>
    )
}

export default Footer