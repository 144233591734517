import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

const defaultState = {
    option: "",
}

const defaultStateName = {
    installationName: "",
}

export const getInstallationOption = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.INSTALLATION_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.INSTALLATION_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the installation option"
            }
        case ActionTypes.INSTALLATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updateInstallation = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_INSTALLATION:
            return {
                ...state,
                option: payload.option,
            }
        default:
            return state
    }
}

export const updateInstallationName = (state = defaultStateName, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_INSTALLATION_NAME:
            return {
                ...state,
                installationName: payload.installationName,
            }
        default:
            return state
    }
}