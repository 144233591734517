import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { validationInputError } from "../../../redux/actions/WooProudctAction"
import { validateInput } from "../../../redux/reducers/utility";

const useStyles = createUseStyles({
  inputText: {
    "&::placeholder": {
      fontFamily: (props) => props.fontStyle,
    },
  },
});

const InputText = forwardRef((props, ref) => {
  const [value, setValue] = useState(
    props.initialValue ? props.initialValue : ""
  );

  //const valid = useSelector(state => state.validateInput)
  //setShowError(validate.validate)
   //const { validate } = valid;
  const [showError, setShowError] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(validationInputError())
    
   },[])




  const handleChange = (event) => {
    let element = event.target;
    let value = element.value;
    let name = element.name;

    if (props.getValue) {
      props.getValue(value);
    }
    setValue(value);
  };


  const classes = useStyles(props);

  const validate = () => {
    const rules = props.validation
    if(props.validation){
      if (rules === "required") { 
        if (!value) {
          return false;
        }
  
      }
    }
    return true
  }

  

  useImperativeHandle(ref, () => {
    return {
      validate: () => validate(),
    }
  })


  return (
    <div className={"input-text-container"}>
      <input
        className={`input-text ${classes.inputText}`}
        name={props.name}
        onChange={(e) => handleChange(e)}
        value={value}
        placeholder={props.placeholder}
        autoComplete="off"
        style={{ fontFamily: props.fontFamily }}
        maxLength={"20"}
        defaultValue={props.defaultValue}
      />
    </div>
  );
});

export default InputText;
