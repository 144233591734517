import firebase from "../../firebase/firebase"

export const backgroundList = () => async dispatch => {

    const ref = firebase.firestore().collection("backgrounds")
    
    /* const backgrounds = [];

        ref.onSnapshot((querySnapshot) => {  
            querySnapshot.forEach((doc) => {
                backgrounds.push(doc.data())
            });
        }) */

    try {
        dispatch({
            type: "BACKGROUND_LIST_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "BACKGROUND_LIST_SUCCESS",
                payload: items
            })         
        })
        
    } catch (error) {
        dispatch({
            type: "BACKGROUND_LIST_FAIL"
        })
    }

    return(
        null
    )
}