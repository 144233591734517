import React, { useState } from "react";
import TabTop from "./TabTop";

const TabsTop = ({ children }) => {
  const [tab, setTab] = useState({
    activeTab: children[0].props.name,
  });

  const onClickTabItem = (tab) => {
    setTab({ activeTab: tab });
  };

  return (
    <div className="tabs-top">
      <ol className="tab-list-top">
        {children.map((child) => {
          const { name, width, height } = child.props;

          return (
              <TabTop
                activeTab={tab.activeTab}
                key={name}
                name={name}
                onClick={onClickTabItem}
                width={width}
                height={height}
              />
          );
        })}
      </ol>
      <div className="tab-content-top">
        {children.map((child) => {
          if (child.props.name !== tab.activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default TabsTop;
