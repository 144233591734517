import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

const defaultState = {
    optionName: "",
}


export const getOption = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.OPTION_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.OPTION_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the more option"
            }
        case ActionTypes.OPTION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updateMoreOption = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_MORE_OPTION:
            return {
                ...state,
                optionName: payload.optionName,
            }
        default:
            return state
    }
}