import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    loading: false,
    data: [],
    errorMsg: ""
}

const defaultState = {
    color: "",
}

const defaultStateColor = {
    colorName: "",
}


export const getColors = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.COLOR_LIST_LOADING:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.COLOR_LIST_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: "unable to get the colors"
            }
        case ActionTypes.COLOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload,
                errorMsg: ""
            }
        default:
            return state
    }
}

export const updateColor = (state = defaultState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_COLOR:
            return {
                ...state,
                color: payload.color,
            }
        default:
            return state
    }
}

export const updateColorName = (state = defaultStateColor, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_COLOR_NAME:
            return {
                ...state,
                colorName: payload.colorName,
            }
        default:
            return state
    }
}