import React from "react";
import { logo, language, cart } from "../../../assets/ImageLibrary";
import Chevron from "../../../assets/IconLibrary/Chevron";

const NavBar = () => {
  return (
    <header className="header-main">
      <div className="logo-container">
        <a href="#">
          <img src={logo} className="logo" alt="" />
        </a>
      </div>
      <div className="nav-main">
        <div className="languages">
          <img className="langauge-icon" src={language} alt="" />
          <Chevron
            //className={`${rotateState}`}
            width={13}
            height={8}
            color="#777"
          />
        </div>
        <div className="cart">
          <div className="cart-icon">
            <img src={cart} alt="" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
