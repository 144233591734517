import { ActionTypes } from '../constant/index'

const INIT_STATE = {
    text: "Hello People"
}

const INIT_STATE_SIZE = {
    size: 90
}

const INIT_STATE_THEME = {
    theme: "sunTheme"
}

const INIT_STATE_COUNT = {
    count: 1
}
const defaultOption = {
    text: "",
    font: "",
    color: "",
    plexiglasColor: "",
    moreOptions: "",
    pvcColor: "",
    installationOptions: "",
    connectoinCable: ""
};

const defaultLoadingState = {
    loading: false
};

const INIT_STATE_CART = {
    options: {...defaultOption },
}

const defaultValidateState = {
    validate: false
}

const defaultValidateStateError = {
    empty: false
}
export const updateText = (state = INIT_STATE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_INPUT:
            return {...state, text: payload.text }
        default:
            return state
    }
}

export const updateSize = (state = INIT_STATE_SIZE, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.UPDATE_SIZE:
            return {...state, size: payload.size }
        default:
            return state
    }
}

export const theme = (state = INIT_STATE_THEME, action) => {
    switch (action.type) {
        case ActionTypes.SWITCH_THEME:
            return { theme: action.theme }
        default:
            return state
    }
}

export const addToCart = (state = INIT_STATE_COUNT, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.ADD_TO_CART_QTY:
            return {
                ...state,
                count: payload.count,
            }
        default:
            return state
    }
}

export const addToCartOption = (state = INIT_STATE_CART, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.ADD_TO_CART_OPTIONS:
            return {
                ...state,
                text: payload.text,
                font: payload.font
            }
        default:
            return state
    }
}


export const loadingPage = (state = defaultLoadingState, action) => {
    const { type } = action
    switch (type) {
        case ActionTypes.SHOW_LOADER:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.HIDE_LOADER:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}

export const validateInput = (state = defaultValidateState, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.VALIDATE_INPUT:
            return {
                ...state,
                validate: payload,
            }
        default:
            return state
    }
}

export const validateInputEmpty = (state = defaultValidateStateError, action) => {
    const { type, payload } = action
    switch (type) {
        case ActionTypes.SHOW_ERROR:
            return {
                ...state,
                error: payload,
            }
        default:
            return state
    }
}