import React from 'react'

const Chevron = (props) => {
    return (
        <svg
            className={props.className}
            height={props.height}
            width={props.width}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 13 8"><path d="M2.00006 2.14285L6.57149 6.14285L11.1429 2.14285" stroke={props.color} strokeWidth="2" strokeLinecap="square">
            </path>

        </svg>
    )
}

export default Chevron
