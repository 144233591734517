import firebase from "../../firebase/firebase"

export const colorsList = () => async dispatch => {
    const ref = firebase.firestore().collection("colors")
    
   /*  const colors = [];

        ref.onSnapshot((querySnapshot) => {
            
            querySnapshot.forEach((doc) => {
                colors.push(doc.data())
            });
        }) */
    try {
        dispatch({
            type: "COLOR_LIST_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "COLOR_LIST_SUCCESS",
                payload: items
            })         
        })

     /* dispatch({
            type: "COLOR_LIST_SUCCESS",
            payload: colors
        }) */
    } catch (error) {
        dispatch({
            type: "COLOR_LIST_FAIL"
        })
    }
}