import React, { useState } from "react";

const SwitchItem = (props) => {
  const [value, setValue] = useState(
    props.initialValue ? props.initialValue : false
  );

  const handleChange = (event) => {
    let element = event.target;
    let value = element.checked;
    let name = element.name;

    if (props.getValue) {
      props.getValue(name, value);
    }
    setValue(value);
  };

  return (
    <div className="switch-container">
      <input
        className="switch-toggle"
        type="checkbox"
        //value={value}
        //onChange={(e) => handleChange(e)}
        value={props.value}
        onChange={props.onChange}
      />
      {props.labelOn && (
        <label htmlFor="" className="onbtn">
          {props.labelOn}
        </label>
      )}
      {props.labelOff && (
        <label htmlFor="" className="offbtn">
          {props.labelOff}
        </label>
      )}
    </div>
  );
};

export default SwitchItem;