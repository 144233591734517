import React, { Fragment, useEffect, useState } from "react";
import { mainScreen } from "../../assets/ImageLibrary";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";

import makerjs from "makerjs";
import opentype from "opentype.js";

import IconLibrary from "../../assets/IconLibrary/IconLibrary";
import { wall1, wall2, wall3, wall4, wall5 } from "../../assets/ImageLibrary";
import { ActionTypes } from "../../redux/constant";
import PageLoader from "../../components/FormElement/PageLoader/PageLoader";
import SkeletonViewBox from "../../components/FormElement/Skeleton/SkeletonViewBox";
const ViewBox = () => {
  const [onState, setOnState] = useState(true);
  const [offState, setOffState] = useState(false);
  const [lightState, setLightState] = useState(true);
  const [display, setDisplay] = useState(false);
  const [slider, setSlider] = useState(mainScreen);

  const [switchTheme, setSwitchTheme] = useState(false);
  const [switchThemeSun, setSwitchThemeSun] = useState(true);
  const [switchThemeMoon, setSwitchThemeMoon] = useState(false);

  const input = useSelector((state) => state.updateText);
  const fontList = useSelector((state) => state.getFonts);
  const setColor = useSelector((state) => state.updateColor);
  const selectedFont = useSelector((state) => state.updateFontFamily);
  const setFont = useSelector((state) => state.updateFontFamily);
  const output = useSelector((state) => state.output);

  console.log("input =========>", input);
  console.log("output =========>", output);

  const [svg, setSvg] = useState("");
  const dispatch = useDispatch();
  const colorTextShadow = setColor.color;

  useEffect(() => {
    // debounce input by 200ms
    const delay = 200;
    const handler = setTimeout(() => {
      generateSvg(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };

    // selectedFont,input
  }, [selectedFont, input, lightState]);
  useEffect(() => {
    // debounce input by 200ms
    const delay = 200;
    const handler = setTimeout(() => {
      generateSvg(true);
    }, delay);

    return () => {
      clearTimeout(handler);
    };

    // selectedFont,input
  }, [setColor]);

  const generateSvg = (color_changed = true) => {
    const { fontFamily, src } = selectedFont;
    if (!fontFamily || !src) {
      return;
    }
    opentype.load(src, (err, font) => {
      const text = input.text,
        size = 100,
        union = false,
        bezierAccuracy = 1,
        centerCharacterOrigin = false,
        separate = true;
      var textModel = new makerjs.models.Text(
        font,
        text,
        size,
        union,
        centerCharacterOrigin,
        bezierAccuracy
      );
      if (separate) {
        for (var i in textModel.models) {
          textModel.models[i].layer = i;
        }
      }
      var svg_out = makerjs.exporter.toSVG(textModel);
      const div = document.createElement("div");
      div.innerHTML = svg_out;
      const svgDOM = div.querySelector("svg");
      const strokeWidth = 3;
      const strokeColor = setColor.color ?? "#000";
      lightState &&
        (svgDOM.style[
          "filter"
        ] = `drop-shadow(0px 0px 10px ${strokeColor} ) drop-shadow(0px 0px 15px ${strokeColor} )`);
      let paths = div.querySelectorAll("path");
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        path.setAttribute("stroke-width", `${strokeWidth}px`);
        path.setAttribute("stroke", "none");
        if (lightState) {
          path.setAttribute("fill", "rgb(255, 255, 255)");
          path.setAttribute("fill-opacity", "0.9");
        } else {
          path.setAttribute("fill", `${strokeColor}`);
          path.setAttribute("fill-opacity", "1");
        }
      }
      let styledSvg = div.innerHTML;
      dispatch({
        type: "UPDATE_SVG",
        payload: {
          svg: styledSvg,
          text: !fontList.loading ? input.text : "Hello people",
          size: size,
          color_changed: color_changed,
        },
      });
      const svgHeight = svgDOM.getAttribute("height");
      const svgWidth = svgDOM.getAttribute("width");
      const svgDOMaspect = svgWidth / svgHeight;

      if (svgDOMaspect > 0.3 && text.length < 8) {
        // if height too big
        svgDOM.setAttribute("height", "21vh");
        svgDOM.setAttribute("width", `${21 * svgDOMaspect}vh`);
      } else {
        svgDOM.setAttribute("width", "42vw");
        svgDOM.setAttribute("height", `${42 / svgDOMaspect}vw`);
      }
      styledSvg = div.innerHTML;
      setSvg(styledSvg);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      // to make this update happen after svg is created
      dispatch({ type: ActionTypes.RESET_SVG_TO_MIN_SIZE });
    }, 400);
  }, [selectedFont]);
  const handleClickOn = () => {
    setLightState(true);
    setOnState(true);
    setOffState(false);
  };

  const handleClickOff = () => {
    setLightState(false);
    setOnState(false);
    setOffState(true);
  };

  const handleClickSun = () => {
    setDisplay(true);
  };

  const handleClickMoon = () => {
    setDisplay(false);
  };
  //console.log(`---: ViewBox -> output.width `, output.width);

  const handleImage_1 = () => {
    setSlider(wall1);
  };

  const handleImage_2 = () => {
    setSlider(wall2);
  };
  const handleImage_3 = () => {
    setSlider(wall3);
  };
  const handleImage_4 = () => {
    setSlider(wall4);
  };
  const handleImage_5 = () => {
    setSlider(wall5);
  };
  const handleImage_6 = () => {
    setSlider(mainScreen);
  };

  const handleSun = () => {
    setSwitchThemeMoon(false);
    setSwitchThemeSun(true);
    setSwitchTheme(false);
  };

  const handleMoon = () => {
    setSwitchThemeMoon(true);
    setSwitchThemeSun(false);
    setSwitchTheme(true);
  };


  return (
    <React.Fragment>
      
        <div className="viewbox-container">
          <div className="theme-container">
            <div className="theme">
              <IconLibrary
                width={24}
                height={24}
                fill={switchThemeSun ? "#F2D03B" : "#FFFFFF"}
                name="sun"
                onClick={handleSun}
              />

              <IconLibrary
                width={24}
                height={24}
                fill={switchThemeMoon ? "#F2D03B" : "#FFFFFF"}
                color={switchThemeMoon ? "#F2D03B" : "#FFFFFF"}
                name="moon"
                onClick={handleMoon}
              />
            </div>
            <div className="btn-theme">
              <button
                className={`btn ${onState ? "btn-on" : ""}`}
                onClick={handleClickOn}
              >
                ON
              </button>
              <button
                className={`btn ${offState ? "btn-on" : ""}`}
                onClick={handleClickOff}
              >
                OFF
              </button>
            </div>
          </div>
          <Draggable bounds="parent">
            <div
              className={`viewbox-text cursor-move ${
                lightState ? "line-sun" : "line-moon"
              }`}
              style={{
                textShadow: `${
                  colorTextShadow && `0px 2px 13px ${colorTextShadow}`
                } `,
              }}
            >
                <div
                id="svg-holder"
                className={`${lightState ? "line-sun-svg" : "line-moon-svg"}`}
                dangerouslySetInnerHTML={{ __html: svg }}
              ></div>
              {!isNaN(output.width) && (
                <div className="svg-width">{output.width >> 0}cm</div>
              )}
              {!isNaN(output.height) && (
                <div className="svg-height">
                  <div className="height-text">{output.height >> 0}cm</div>
                </div>
              )}
            </div>
          </Draggable>
          <div className="slideshow-container">
            <img
              className="view-box-image"
              src={slider}
              alt=""
              draggable="false"
              style={{ opacity: switchTheme ? 0.3 : 1, maxHeight: '100%' /* , height: "971px" */ }}
              id="slider"
            />
            <ul className="navigation">
              <li onClick={handleImage_1}>
                <img src={wall1} alt="" />
              </li>
              <li onClick={handleImage_2}>
                <img src={wall2} alt="" />
              </li>
              <li onClick={handleImage_3}>
                <img src={wall3} alt="" />
              </li>
              <li onClick={handleImage_4}>
                <img src={wall4} alt="" />
              </li>
              <li onClick={handleImage_5}>
                <img src={wall5} alt="" />
              </li>
              <li onClick={handleImage_6}>
                <img src={mainScreen} alt="" />
              </li>
            </ul>
          </div>
          <PageLoader />
        </div>
      {/* {fontList.loading && (
        <React.Fragment>
          <SkeletonViewBox theme="light"/>
        </React.Fragment>
      )} */}
    </React.Fragment>
  );
};

export default ViewBox;
