import firebase from "../../firebase/firebase"

export const installationList = () => async dispatch => {

    const ref = firebase.firestore().collection("installations")
    
    /* const installations = [];

        ref.onSnapshot((querySnapshot) => {
            
            querySnapshot.forEach((doc) => {
                installations.push(doc.data())
            });
        }) */

    try {
        dispatch({
            type: "INSTALLATION_LIST_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "INSTALLATION_LIST_SUCCESS",
                payload: items
            })         
        }) 

        /* dispatch({
            type: "INSTALLATION_LIST_SUCCESS",
            payload: installations
        }) */
        
    } catch (error) {
        dispatch({
            type: "INSTALLATION_LIST_FAIL"
        })
    }
}