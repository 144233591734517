import React, { useState } from "react";
import { createUseStyles } from "react-jss";



const useStyles = createUseStyles({
  textMultiselectFont: {
     fontFamily: props => props.fontFamily
    }
})

const MultiSelect = (props) => {
  const [value, setValue] = useState(
    props.initialValue ? props.initialValue : ""
  );

  const handleChange = (event) => {
    let element = event.target;
    let value = element.value;

    if (props.getValue) {
      props.getValue(value);
    }
    setValue(value);
  };

  const classes = useStyles(props)

  //console.log(classes)

  return (
    <div className="multiselect" onClick={props.onClick}>
      <label htmlFor={props.id} className="custom-multiselect-font">
      <input
        type="radio"
        id={props.id}
        name={props.name}
        onChange={(e) => handleChange(e)}
        value={value}
        className="custom-multiselect"
        checked={props.checked}
      />
      <span className={`text-multiselect-font ${classes.textMultiselectFont}`}>
          <p style={props.style}>{props.label}</p>
      </span>
        
      </label>
    </div>
  );
};

export default MultiSelect;