import { ActionTypes } from "../constant/index";

/* 
basic_price=(path_length x lm) + (no_of_alphabet x setup_price)
price=basic_price  + basic_price((sum_of_factors)- (count_of_factors-1) )  +ic
 */

/* what i got?
// font api
minHeight: 30
minWidth: 23
pricePathLenght: "20"
priceShape: "20"
// bg plexes
factorPrice: "1.22"
// pvcs
factorPrice: "1.22"
// installations
factorPrice: "1.5"
// cable
price: "10"
 
// no lm
*/

const defaultFactors = {
    // pre defined
    lm: 0,
    setup_price: 0,
    setupPrices: 0,

    // factors
    cl: 0, //color??  -- done
    pvc: 0, // --
    plx: 0, //
    mo: 0, //
    io: 0, //installtion

    //   const
    ic: 0,
    // calculate
    basic_price: 0,
};

const defaultState = {
    factors: {...defaultFactors },
    text: "",
    pathLength: "",
    pathLength100: "",
    width100: "",
    height100: "",
    aspect: "",
    width: "",
    height: "",
    minWidth: "",
    minHeight: "",
    charCount: "",
    svg: "",
};

export const updateSvg = (state = defaultState, action) => {
    var { type, payload } = action;
    switch (type) {
        case ActionTypes.SET_MIN_MAX_DIMS:
            var { width, height } = payload;
            return {...state, minHeight: height, minWidth: width };

        case ActionTypes.UPDATE_SVG:
            var { svg, text, color_changed } = payload;
            var nextState = {};
            nextState.text = text;
            nextState.svg = svg;
            if (color_changed) {
                return {...state, ...nextState };
            }
            var x = document.createElement("div");
            x.innerHTML = svg;
            var paths = x.querySelectorAll("path");
            var totalLength = 0;
            for (var i = 0; i < paths.length; i++) {
                var path = paths[i];
                var length = path.getTotalLength();
                totalLength += length;
            }
            var svgDom = x.querySelector("svg");
            var width = parseFloat(svgDom.getAttribute("width"));
            var height = parseFloat(svgDom.getAttribute("height"));

            nextState.pathLength = totalLength;
            nextState.pathLength100 = totalLength;
            nextState.width100 = width;
            nextState.height100 = height;
            nextState.width = width;
            nextState.height = height;
            nextState.charCount = text.replace(/ /g, "").length;
            nextState.aspect = width / height;

            return {
                ...state,
                ...nextState,
            };

        case ActionTypes.UPDATE_WIDTH:
            var { aspect, width100, pathLength100 } = state;
            var { width: payloadWidth } = payload;
            var updatedState = {
                width: payloadWidth,
                height: payloadWidth / aspect,
                pathLength: pathLength100 * (payloadWidth / width100),
            };
            return {...state, ...updatedState };

        case ActionTypes.UPDATE_HEIGHT:
            var { aspect, height100, pathLength100 } = state;
            var { height: payloadHeight } = payload;

            var updatedState2 = {
                height: payloadHeight,
                width: payloadHeight * aspect,
                pathLength: pathLength100 * (payloadHeight / height100),
            };
            return {...state, ...updatedState2 };

        case ActionTypes.RESET_SVG_TO_MIN_SIZE:
            var { aspect, minHeight, height100, pathLength100 } = state;
            var updatedState3 = {
                height: minHeight,
                width: minHeight * aspect,
                pathLength: pathLength100 * (minHeight / height100),
            };
            return {...state, ...updatedState3 };

        case ActionTypes.UPDATE_FACTORS:
            var { factor_name, value } = payload;
            return {
                ...state,
                factors: {...state.factors, [factor_name]: parseFloat(value) },
            };
        default:
            return state;
    }
};