import React, { useState, useRef, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../redux/constant";
import IconLibrary from "../../../assets/IconLibrary/IconLibrary";
import { Route } from "react-router-dom";

const Button = (props) => {
  const [count, setCount] = useState(1);
  const [inputValue, setInputValue] = useState({ value: 0 });

  const refSect = useRef(null);

  const IncrementItem = (e) => {
    if (count < 10) setCount(count + 1);
  };

  const DecreaseItem = () => {
    if (count > 1) setCount(count - 1);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: ActionTypes.ADD_TO_CART_QTY,
      payload: {
        count: count,
      },
    });
  }, [count]);

  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-cart"
        style={props.style}
        onClick={(e) => props.onClick(e)}
      >
        {props.text && <span className="btn-text">{props.text}</span>}
      </button>
      <div className="cart-count">
        <div className="count">{count}</div>
        <div className="btn-quantity">
          <div onClick={IncrementItem}>
            <IconLibrary
              className="upbtn"
              width={15}
              height={10}
              fill="#626262"
              name="up"
            />
          </div>
          <div onClick={DecreaseItem}>
            <IconLibrary
              className="downbtn"
              width={15}
              height={10}
              fill="#626262"
              name="down"
            />
          </div>
        </div>
      </div>
      
    </Fragment>
  );
};

export default Button;
