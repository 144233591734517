import firebase from "../../firebase/firebase"

export const cablesList = () => async dispatch => {
    const ref = firebase.firestore().collection("cables")
    
    /* const cables = [];

        ref.onSnapshot((querySnapshot) => {
            
            querySnapshot.forEach((doc) => {
                cables.push(doc.data())
            });
        }) */

    try {
        dispatch({
            type: "CABLE_LIST_LOADING"
        })

        ref.get().then((item) => {
            const items = item.docs.map((doc) => doc.data());
            dispatch({
                type: "CABLE_LIST_SUCCESS",
                payload: items
            })         
        })
/* 
        dispatch({
            type: "CABLE_LIST_SUCCESS",
            payload: cables
        }) */

    } catch (error) {
        dispatch({
            type: "CABLE_LIST_FAIL"
        })
    }
}