import React, { useState } from "react";
import { useSelector } from "react-redux";

const RangeInput = (props) => {
  const [value, setValue] = useState(
    props.initialValue ? props.initialValue : 0
  );

  const output = useSelector((state) => state.output);
  // console.log("---: RangeInput -> output", output);


  const handleChange = (event) => {
    let element = event.target;
    let value = element.value;
    // if(value>props.min && value<props.max){
      setValue(value);
      if (props.getValue) {
        props.getValue(event);
      }
    // }
  };


  return (
    <div className="input-range-container">
      {props.label && <h1 id="text-title">{props.label}</h1>}
      <hr className="tab-list__line"/>
      <div className="input-range-content">
        <input
          type="range"
          className="input-range"
          min={props.min}
          max={props.max}
          step={props.step}
          onChange={handleChange}
          value={props.rangeValue}
          maxLength={props.defaultLength}
        />
        {/* {props.rangeValue && ( */}
          <input
            type="number"
            className="range-input-value"
            value={props.widthValue>>0}
            onChange={props.handleWidthChange}
            maxLength={5}
            onBlur={props.onBlur}
          />
        {/* )} */}
      </div>

    <div className="range-sizing">
          <span className="size-title">Breite x Höhe :</span>
          <span className="range-value">{`${output.width>>0} cm x ${output.height>>0} cm`}</span>
    </div>
      
    </div>
  );
};

export default RangeInput;