import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconLibrary from "../../assets/IconLibrary/IconLibrary";
//import { optionList } from "../../redux/actions/optionList";
import { ActionTypes } from "../../redux/constant";

const CarouselOption = ({ options }) => {
  const [inputValueOption, setInputValueOption] = useState("");

  //const options = useSelector((state) => state.getOption);
  const dispatch = useDispatch();

  /* useEffect(() => {
    FetchData();
  }, []); */

  /* useEffect(() => {

    const moreOptionData = options?.data[0]?.optionName;

    console.log("MORE OPTION VALUE", options.data);
    dispatch({
      type: ActionTypes.UPDATE_MORE_OPTION,
      payload: {
        optionName: moreOptionData
      },
    });

  },[])  */

  const [x, setX] = useState({
    imageIndex: 0,
    translateX: 0,
  });


  /* const FetchData = () => {
    dispatch(optionList());
  };
 */

  /* useEffect(() => {

      dispatch({
        type: ActionTypes.UPDATE_MORE_OPTION,
        payload: {
          optionName: 'Cut Around Acrylic',
        },
      }); 
    
  }, [options]); */


  const handlePrev = () => {
    if (x.imageIndex !== 0) {
      setX({
        imageIndex: x.imageIndex - 1,
        translateX: x.translateX + 96,
      });

      if (!options.data.length) {
        return 0;
      }
      const moreOptionData = options.data[x.imageIndex ?? 0];

      const { optionName: value } = moreOptionData;
      const { factorPrice: price } = moreOptionData;
      setInputValueOption({ ...inputValueOption, name: value });

      dispatch({
        type: ActionTypes.UPDATE_MORE_OPTION,
        payload: {
          optionName: value,
        },
      });

      dispatch({
        type: ActionTypes.UPDATE_FACTORS,
        payload: {
          factor_name: "mo",
          value: price,
        },
      });
      //translateX += 300;
    }

  };

  const handleNext = () => {
    
    if (x.imageIndex !== 3) {
      // x.imageIndex++;
      //translateX -= 300;
      setX({
        imageIndex: x.imageIndex + 1,
        translateX: x.translateX - 96,
      });

      if (!options.data.length) {
        return 0;
      }
      const moreOptionData = options.data[x.imageIndex ?? 3];

      const { optionName: value } = moreOptionData;
      const { factorPrice: price } = moreOptionData;
      setInputValueOption({ ...inputValueOption, name: value });
     
      dispatch({
        type: ActionTypes.UPDATE_FACTORS,
        payload: {
          factor_name: "mo",
          value: price,
        },
      });

      dispatch({
        type: ActionTypes.UPDATE_MORE_OPTION,
        payload: {
          optionName: value,
        },
      });
    }
  };

  return (
    <div className="option-carousel-slider" style={{marginTop: '16px'}}>
      <div className="option-carousel-slide">
        <div className="carousel">
          <div
            className="carousel__images"
            style={{ transform: `translateX(${x.translateX}px)` }}
          >
            {options.data.map((option, fontIndex) => {
             // return option.optionPicture.map((optionImage, index) => {
                return <img id={option.id} src={option.optionPicture} alt="" />
              //});
            })}
          </div>
        </div>
        <div className="slides-title">
          {options.data.map((option, inedx) => {
            return x.imageIndex === inedx && `${option.optionName}`;
          })}
        </div>
      </div>
      <div className="option-carousel-control">
        <div className="option-carousel-buttons">
          <RightArrow goToNextSlide={() => handleNext(x.imageIndex)} />
          <LeftArrow goToPrevSlide={handlePrev} />
        </div>
      </div>
    </div>
  );
};

const LeftArrow = (props) => {
  return (
    <IconLibrary
      className="goBottom"
      width={15}
      height={7}
      fill="#626262"
      name="bottom"
      onClick={props.goToPrevSlide}
    />
  );
};

const RightArrow = (props) => {
  return (
    <IconLibrary
      className="goTop"
      width={15}
      height={7}
      fill="#626262"
      name="top"
      onClick={props.goToNextSlide}
    />
  );
};

export default CarouselOption;
